import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import { useTealiumContext } from "../../../../contexts/Tealium"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { SeriesOffersModel } from "../SeriesOffers.d"
import { FilterSidebarProps } from "./FilterSidebar.d"

const FilterSidebar = ({
  dispatch,
  state,
  modelNames,
  uniqueOfferTypes,
  seriesYears,
  seriesName,
  ...remainingProps
}: FilterSidebarProps): JSX.Element => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData, vehicleTealData } = useTealiumContext()
  const { _ } = useContext(LanguageContext)

  // Parameters:  Name of event and an object with additional properties for tealium event call if required
  const handleTealEvent: (eventName: string, moreData?: {}) => void = (
    eventName = "No event name provided",
    moreData = {}
  ) => {
    trackTealEvent({
      tealium_event: eventName,
      page_type: tealPageData?.page_type,
      vehicle_model: vehicleTealData?.vehicle_model,
      vehicle_segment_vehicle_page:
        vehicleTealData?.vehicle_segment_vehicle_page,
      vehicle_model_truncated: vehicleTealData?.vehicle_model_truncated,
      ...moreData,
    })
  }

  return (
    <article {...remainingProps}>
      <section
        css={[
          tw`flex justify-between text-xs border-b border-gray-300 pb-2 mb-2`,
        ]}
      >
        <div>{_("Filter")}</div>
        <button
          onClick={() => dispatch({ clearAll: true })}
          css={[
            tw`p-0 text-xs font-book text-red-400 normal-case cursor-pointer`,
          ]}
        >
          {_("Clear All")}
        </button>
      </section>
      {modelNames && (
        <section css={[tw`flex flex-col gap-2 mb-7`]}>
          <h3 css={[tw`font-semibold`]}>{_("Models")}</h3>
          {modelNames?.map((node: SeriesOffersModel) => {
            return (
              <div>
                <input
                  type="checkbox"
                  checked={state?.models?.includes(node)}
                  onChange={() => {
                    dispatch({
                      type: node,
                      isChecked: state?.models?.includes(node),
                      filterType: "models",
                    })
                    handleTealEvent("refinement", {
                      refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|${seriesName}|${node}`,
                    })
                  }}
                />
                <span css={[tw`ml-2 font-semibold`]}>{node}</span>
              </div>
            )
          })}
        </section>
      )}

      {uniqueOfferTypes && (
        <section css={[tw`flex flex-col gap-2 mb-7`]}>
          <h3 css={[tw`font-semibold`]}>{_("Offer Types")}</h3>
          <ul aria-label="offer types">
            {uniqueOfferTypes?.map((node: string) => {
              let label = node

              return (
                <li>
                  <input
                    type="checkbox"
                    name={node}
                    id={node}
                    checked={state?.types?.includes(node)}
                    onChange={e => {
                      dispatch({
                        type: node,
                        isChecked: state?.types?.includes(node),
                        filterType: "types",
                      })
                      handleTealEvent("refinement", {
                        refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|${seriesName}|${node}`,
                      })
                    }}
                  />
                  <label for={node} css={[tw`ml-2 font-semibold`]}>
                    {label}
                  </label>
                </li>
              )
            })}
          </ul>
        </section>
      )}

      {seriesYears && (
        <section css={[tw`flex flex-col gap-2 mb-7`]}>
          <h3 css={[tw`font-semibold`]}>{_("Year")}</h3>
          {seriesYears?.map((node: Object[], index: number) => {
            return (
              <div>
                <input
                  type="checkbox"
                  checked={state?.years?.includes(node)}
                  onChange={() => {
                    dispatch({
                      type: node,
                      isChecked: state?.years?.includes(node),
                      filterType: "years",
                    })
                    handleTealEvent("refinement", {
                      refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|${seriesName}|${node}`,
                    })
                  }}
                />
                <span css={[tw`ml-2 font-semibold`]}>{node}</span>
              </div>
            )
          })}
        </section>
      )}
    </article>
  )
}

export default FilterSidebar
